<template>
  <b-row>
    <b-col cols="12">
      <table-fe
        :tableTitle="this.$route.meta.title"
        apiUrl="province"
        apiBase="/province"
        creates="false"
        updates="false"
        deletes="false"
        shows="false"
        search="true"
        :perPage="perPage"
        :currentPage="currentPage"
        :totalRows="totalRows"
        :fields="fields"
        :items="getItems"
        :sort-by.sync="sortBy"
        @changePerPage="changePerPage"
        @changeCurrentPage="changeCurrentPage"
      >
      </table-fe>
    </b-col>
  </b-row>
</template>

<style>
.width160px {
  width: 160px !important;
  background-color: "red !important";
}
</style>

<script>
import { BRow, BCol } from "bootstrap-vue";
import TableFe from "@/views/table/bs-table/TableFE";
import { EventBus } from "@/eventBus.js";
import axios from "@axios";

export default {
  name: "UsersList",
  components: {
    BRow,
    BCol,
    TableFe,
  },
  data() {
    return {
      config: {
        api: "/province",
      },
      perPage: 1,
      currentPage: 1,
      totalRows: 10,
      sortBy: "id",
      fields: [
        "no",
        { key: "id", label: "ID", sortable: true },
        { key: "name", label: "Nama", sortable: true },
        // { key: 'Aksi', label: 'Aksi', thStyle: 'width:290px'}
      ],
      items: [],
    };
  },
  mounted() {
    let _ = this;
    if (_.$route.params) {
      if (_.$route.params.event) {
        _.makeToast(
          _.$route.params.event,
          _.$route.params.title,
          _.$route.params.text
        );
      }
    }
  },
  methods: {
    getItems(ctx, callback) {
      console.log(ctx);
      const _ = this;
      let filter = ctx.filter ? "&key=" + ctx.filter : "";
      let orderBy = ctx.sortDesc == true ? "asc" : "desc";
      let spliting = ctx.sortBy.split(".");
      let sortBy = spliting[spliting.length - 1];
      if (sortBy.includes("_cast") == true) {
        sortBy = sortBy.split("_");
        sortBy = sortBy[0];
      }
      sortBy = sortBy != "" ? sortBy : "id";
      axios
        .get(
          _.config.api +
            "?per_page=" +
            _.perPage +
            "&page=" +
            _.currentPage +
            filter +
            "&orderby=" +
            sortBy +
            "&sort=" +
            orderBy
        )
        .then((resp) => {
          const consume = resp.data.data;
          _.perPage = 10;
          _.currentPage = consume.currentPage;
          _.totalRows = consume.totalData;
          callback(consume.rows || []);
        })
        .catch((err) => {
          console.log(err);
        });
      return null;
    },
    renderTable(perPage, currentPage) {
      axios
        .get(_.config.api + "?per_page=" + perPage + "&page=" + 1)
        .then((resp) => {
          const consume = resp.data.data;
          _.totalRows = consume.totalData;
          _.currentPage = consume.currentPage;
          // _.perPage = 10;
          _.perPage = consume.perPage;
          _.items = consume.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    changePerPage(perPage) {
      let _ = this;
      console.log("parent: " + perPage);
      _.renderTable(perPage);
    },
    changeCurrentPage(currentPage) {
      let _ = this;
      _.currentPage = currentPage;
    },
    makeToast(variant = null, title = "Title here...", text = "Text here...") {
      this.$bvToast.toast(text, {
        title,
        variant,
        solid: true,
      });
    },
  },
};
</script>
